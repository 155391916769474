@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
:root {
    --border_radius: 20px;
    --border_radius_min: 10px;
}

a {
    text-decoration: none;
}

body {
    width: 100%;
    overflow-x: hidden;
    background-color: white;
}

::-webkit-scrollbar {
    width: 5px !important;
    background-color: #ffe6d6;
}

::-webkit-scrollbar-thumb {
    background-color: #e19e5e;
    /* Set the color of the scrollbar thumb */
}

button {
    outline: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

* {
    margin: 0px;
    outline: none !important;
    padding: 0px;
    transition-timing-function: ease-in-out;
    font-family: "Montserrat", serif;
    box-sizing: border-box;
}
.big_navbar {
    width: 100%;
    padding: 0 65px;
    border-bottom: 1px solid#E7CEB7;
    overflow: visible;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 86px;
    background-color: #ffffff;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 955;
}
.google_ads{width: 100%; height: 100%; background-color: #ECECEC !important; border-radius: 20px; overflow: hidden;}
.brand{display: flex; align-items: center;}
.brand img{height: 65px;}
.navbar_left{display: flex; align-items: center;}
.navbar_left ul{padding-left: 20px; display: flex; align-items: center;}
.navbar_left li{padding: 0 12px; height: 24px; display: flex;}
.navbar_link, .more_item p, .filter_nav_btn{font-size: 16px; color: black; line-height: 24px; transition: 0.3s; font-weight: 500;}
.more button{display: flex; transform: none !important; align-items: center;}
.more{position: relative;}

.more span{font-size: 16px; line-height: 24px; display: flex; align-items: center; margin-left: 5px;}
.more_list{position: absolute; top:62px; left: 0px; display: flex !important; padding-left: 0px;  
      align-items: flex-start;background: white !important; border-radius: 20px; width: max-content; 
      box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 7px !important;  padding: 0px 20px; max-height: 0; transition: 0.3s; overflow: hidden;}
.open_drop_more{padding: 20px; max-height: 600px;}
.more_item{display: flex; align-items: center; padding: 10px 0; cursor: pointer; justify-content: space-between; width: 100%; } 
.more_item span{margin-left: 20px; color: black; font-size: 22px; }
.more_item p{transition: 0.3s;}
.more_big_list{padding-right:10px;}
.more_big_list_two{padding-left:20px;  border-left: 1px solid #e19e5e; display: flex; flex-direction: column;  flex-wrap: wrap; height: 390px; width: max-content;}
.more_big_list_two .more_item{max-width: 300px; width: auto; min-width: 250px; padding:10px 20px;}

.filter_nav_btn{text-transform: uppercase; display: flex; align-items: center; padding: 5px 14px;border: 1px solid #E7CEB7;  border-radius: 10px; }
.navbar_box{display: flex; align-items: center;}
.login_icon{font-size: 24px;margin-left: 16px; cursor: pointer; transition: 0.3s; color: black; display: flex; align-items: center;}
.filter_nav_btn span{margin-left: 10px;}
.main{width: 100%; height: 100vh; display: flex; padding: 126px 0 40px;}
.main_item{width: 50%; height: 100%; background-size: cover; background-position: center; background-repeat: no-repeat;}
.main_home{background-image: url(./images/main_home.jpg);}
.main_car{background-image: url(./images/main_car.jpg);}
.main_text{width: 100%; height: 100%; display: flex; align-items: center; justify-content: center; padding: 65px;}
.main_text p {color: white; font-size: 42px; line-height: 50px; font-weight: 500;}
.main_text span{font-weight: 700;}
.bests{padding: 85px 65px;}
.title{color: black; font-size: 38px; line-height: 46px; font-weight: 700;}
.best_row{margin-top: 50px;}
.best_col:nth-child(1){padding-right: 100px;}
.best_col:nth-child(2){padding-left: 100px;}
.best_card{display: flex; cursor: pointer; align-items: center; margin-bottom: 20px;}
.best_img{width: 120px; height: 120px; overflow: hidden; border-radius: 50%;}
.best_img_img{width: 100%; height: 100%; border-radius: 50%; background-size: cover; background-position: center; transition: 0.3s; background-repeat: no-repeat;}
.best_home{background-image: url(./images/best_home.png);}
.best_car{background-image: url(./images/best_car.png);}
.best_text{width: calc(100% - 170px); padding: 0 20px;}
.best_arrow{width: 50px; transition: 0.3s; height: 40px; border-radius: 24px; display: flex; align-items: center; justify-content: center; background-color: #E7CEB7; color: black; font-size: 20px; }
.best_text h2{font-size: 15px; font-weight: 600; margin-bottom: 10px; color: black;}
.best_text p{font-size: 15px; font-weight: 400; margin-bottom: 10px; color: #6D6D6D;}
.best_card h1{font-size: 25px; color: black; font-weight: 600; line-height: 30px;}

.carous{width: 100%; padding: 90px 65px;}
.carousel_item{ display: flex; justify-content: space-between; align-items: center; width: 100%;}
.carousel_img{width: 45%;  height: 65vh;position: relative; }
.carousel_img_item {height: 100%; width: 100%; border-radius: 0 252px 0 0; opacity: 0; transition: 1s; background-size: cover; 
    position: absolute; top: 0px; left: 0px; background-position: center; background-repeat: no-repeat;}
.carousel_img_active{opacity: 1;}
.carousel_text{width: 55%; padding-left:4.2vw; display: flex; flex-direction: column; justify-content: center; height: 100%;}
.carousel_text_head{display: flex; align-items: center; margin-bottom: 20px; width: 100%; overflow-x: hidden;}
.carousel_text_head_item{padding-right: 3.4vw; user-select: none; transition: 0.3s; font-size: 18px; color:#AEAEB2; font-weight: 600; cursor: pointer;}
.carousel_text_head_active{color: #252628;}

.carousel_text_box{position: relative; height: 300px;}
.carousel_text_data{position: absolute; top: 0px; z-index: 2; left: 0px; opacity: 0; transition: 1s;}
.carousel_text_data_active{opacity: 1; z-index: 4;}
.carousel_text_data h1{font-size: 42px; line-height: 48px; color: black; font-weight: 700; margin-bottom: 16px;}
.carousel_text_data>p{font-size: 18px; font-weight: 600; line-height: 24px; color: black; margin-bottom: 60px;}
.carousel_btn{display: flex; transition: 0.3s; width: max-content; align-items: center; padding: 13px 22px; background-color: #EAE7DD; border: 1px solid #DDD0C8; border-radius: 24px;} 
.carousel_btn span{font-size: 20px; color: black; margin-right: 14px; display: flex; align-items: center;}
.carousel_btn p{font-size: 15px; line-height: 22px; color: black; font-weight: 600;}
.car_carous .carousel_text{padding-left: 0; padding-right: 4.2vw;}
.car_carous .carousel_img_item{ border-radius: 252px 0 0 0;}
.dash_categories{padding: 70px 65px 0; width: 100%; display: flex; flex-wrap: wrap;}
.categories_card{width: 50%; }
.categories_card img{width: 100%;}
.categories_card:nth-child(even){padding-left: 5vw; margin-bottom: 80px;}
.categories_card:nth-child(odd){padding-right: 5vw; margin-top: 80px;}
.categories_card h1{font-size: 42px; transition: 0.3s; line-height: 50.4px; font-weight: 700; color: #252628; margin-bottom: 40px;}

.big_box_cat{padding: 130px 35px 90px;}
.categories_row{display: flex; justify-content: center; flex-wrap: wrap;}
.big_col{width: 33.33%; padding: 30px 30px;}
.big_cart_image{width: 100%;transition: 0.3s; height: 250px; display: flex; align-items: center; justify-content: center; border-top-right-radius: 120px; overflow: hidden;}
.big_cart_image img{width: 100%;transition: 0.3s !important; transform: scale(1, 1); min-height: 100%;}
.big_cart_text{font-weight: 600; transition: 0.3s; font-size: 22px; line-height: 26.4px; color: black; height: 80px; overflow: hidden;}
.loader {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9998;
    background-color: #ffffffe0;
    backdrop-filter: blur(10px);
}
.active_link_nav{color: #e19e5e;}
.loader span {
    width: 13vw !important;
    height: 13vw !important;
}

.footer_top{display: flex; align-items: center; padding: 40px 65px; border-top: 1px solid #e19e5e; justify-content: space-between;} 
.footer_img img{width: 60px;}
.footer_menu{display: flex; align-items: center;}
.footer_menu .more_item{white-space: nowrap; padding: 0 16px;}
.footer_links{display: flex; align-items: center;}
.footer_links a{margin: 0 6px; transition: 0.3s; font-size: 24px; color: #252628;}

.footer_bottom{padding: 0 65px;}
.footer_bottom_content{border-top: 2px solid #252628; width: 100%; display: flex; align-items: center; justify-content: space-between; flex-wrap: wrap; padding: 30px 0;}
.footer_bottom_content a{font-size: 14px; transition: 0.3s; line-height: 21px; color: #252628; margin: 0 12px; font-weight: 400;}

.products_filter{width: 100%;   background-size: 100% auto; max-height: 0; background-repeat: no-repeat;
    position: relative; z-index: 12; overflow: hidden;
    background-position: center;   transition: 0.5s;} 
.filter_box{padding:120px 65px 30px;  width: 100%; background-color: #0000008c; backdrop-filter: blur(2px);}
.select {
    width: 100%;
}

label {
    font-size: 16px !important;
    font-weight: 400;
    white-space:  nowrap !important;
    padding-left: 11px;
    color: #cdcdcd !important;
}

.select_input {
    width: 100%;
    height: 50px;
    transition: 0.3s;
    cursor: pointer;
    padding: 0px 11px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border: 1px solid #cdcdcd;
    color: white;
    border-radius: var(--border_radius_min);
}
.sorter_box .select_input{color: #e19e5e; border-color: #e19e5e;}
.sorter_box .select_input span{color: #e19e5e;}
.select_input span{display: flex; align-items: center; color: white;}
.client_content .select_input span{color: #666666;}
#tanlang p,
#tanlang span {
    color: #8f999c;
}

.select_input p {
    font-size: 16px;
    transition: 0.3s;
    margin-bottom: 0px;
    font-weight: 500;
}

.select_input span {
    font-size: 24px;
    transition: 0.3s;
    color: white;
}

.check_input {
    width: 20px !important;
    height: 20px;
}
.select_box_item span{width: calc(100% - 20px);}
.select_box {
    position: relative;
}

.select_box_in {
    position: absolute;
    border: 0px solid #dee2e6;
    border-top: none;
    border-radius: 0 0 var(--border_radius_min) var(--border_radius_min);
    transition: 0.3s;
    z-index: 3888 !important;
    background-color: white;
    top: 0px;
    left: 0px;
    width: 100%;
    box-shadow: rgba(98, 98, 149, 0.1) 0px 7px 10px;
    max-height: 0vh;
    overflow-x: hidden;
    overflow-y: auto;
    height: auto;
}

.select_box_item {
    padding: 6px 11px;
    border-bottom: 1px solid #dee2e6;
    border-left: none;
    display: flex;
    z-index: 1888 !important;
    align-items: center;
    border-right: none;
    font-size:16px;
    cursor: pointer;
    transition: 0.3s;
}

.select_box_item:last-child {
    border-bottom: none !important;
}

input {
    margin-right: 10px;
    height: 50px;
}

.select_box_in::-webkit-scrollbar {
    opacity: 0 !important;
}

.select_active .select_box_in {
    max-height: 400px;
    border-width: 1px;
}

.select_active .select_input {
    border-radius: var(--border_radius_min) var(--border_radius_min) 0 0;
    background-color: #e19e5e;
}

.select_active .select_input span {
    color: white !important;
}

.select_active .select_input p {
    color: white !important;
}
.red_select .select_input p{color: #666666 !important;}
.red_select .select_input span{color: #666666 !important;}
.select_active .select_box_in::-webkit-scrollbar {
    opacity: 1 !important;
}

.filter_col {
    padding: 10px;
}

.input {
    width: 100%;
}

input {
    background-color: transparent;
    border: 1px solid #AEAEB2;
    color: white;
    padding: 0px 11px;
    height:50px;
    border-radius: var(--border_radius_min);
    outline: none;
    font-size: 16px;
    transition: 0.3s;
    font-weight: 500;
    width: 100% !important;
    transition: 0.3s;
}
input::placeholder{color: white;}
.red_input_box input,
.red_select .select_input {
    border-color: #ff7979;
    background-color: #ff797910;
}

.input_input {
    width: 100%;
}

.filter_col_right {
    padding-right: 5px;
}

.filter_col_left {
    padding-left: 5px;
}

.select_empty {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    opacity: 0.7;
}

.no_overflow .select_box_in {
    overflow-y: hidden;
}

.active_select_box_item {
    color: #e19e5e;
    font-weight: 500;
}

.filter_btns {
    width: 100%;
    padding-bottom: 10px;
    padding-top: 20px;
    display: flex;
    justify-content: center;
}

.filter_btns button,
.filter_btns a, .big_navbar .save_btn {
    padding: 0vw 20px;
    height: 50px;
    transition: 0.3s;
    user-select: none;
    display: flex;
    align-items: center;
    margin: 0 10px;
    font-weight: 500;
    font-size: 16px;
    border-radius: var(--border_radius_min);
    white-space: nowrap;
}

.restore_btn {
    background-color: #dee2e6;
    color: #333839;
}

.save_btn {
    background-color: #e19e5e;
    color: white;
}
.big_box_products{padding: 0 65px;}
.big_box_products:has(.card_mini){padding: 0 33px;}
.big_box_products:has(.products_sorter){padding: 0 65px;}
.products_sorter{border-radius: 20px; margin: 30px 0; margin-top: 100px; background-color: white;
    padding: 10px 0; display: flex; align-items: center; justify-content: space-between;}
    .card_icon{display: none;}
.sorter_box{display: flex; align-items: center;}
.sorter_shape{display: flex; align-items: center; margin-left: 20px;}
.sorter_shape button{margin: 0 10px; font-size: 27px; color: #8f999c; display: flex;align-items: center;}
.active_shape{color: #e19e5e !important;}
.sorter_select{width: 250px;}
.money_type button{font-size: 22px; font-weight: 500;}
.mini_card_col {padding: 25px 32px;}
.card{color: black; border-radius: 20px; margin-bottom: 50px; height: 280px; width: 100%; display: flex;  overflow: hidden; 
position: relative; z-index: 0;
}

.card img {min-height: 100% !important;}
.card .lazy-image-container, .card .lazy-load-image-background {min-height: 100%; display: flex;}
.card_mini img {min-height: auto !important;}
.card_mini .lazy-image-container, .card_mini .lazy-load-image-background {min-height: auto; display: flex;}
.card_mini{flex-direction: column; margin-bottom: 0; height: auto;}
.card_text, .card_mini_text{background: #ECECEC !important; width: 100%; padding: 20px;}
.card_img{background: #ECECEC !important;  width: 400px; height: 100%; display: flex; align-items: center; justify-content: center; overflow: hidden;}
.card_mini_img {width: 100%; height: 320px;}
.card h1{font-size: 18px; line-height: 22px; width: 100%;  overflow: hidden; font-weight: 700; margin-bottom: 20px; }
.card_mini h1{height: 44px; margin-bottom: 16px;}
.white_text{color: transparent !important;}
.card_price{display: flex; align-items: flex-end; justify-content: space-between;}
.card_price p{font-size: 20px; line-height: 24px; font-weight: 700;}
.card_price span{font-weight: 500; font-size: 16px; color: #666666;}
.card_item{display: flex; align-items: center; justify-content: flex-start; margin-bottom: 20px;}
.card_mini .card_item{margin-bottom: 16px;}
.card_item span{font-size: 20px; color: black; margin-right: 10px; display: flex; align-self: center;}
.card_item p{font-size: 16px; line-height: 22px; color: #666666;}

.card img{transition: 0.3s !important;}

.service_card .card_mini_price {flex-direction: column !important; align-items: flex-start;}
body:has(.products_box){height: 100vh; overflow: hidden;}
.products_box {width: 100%; height: 100vh; overflow-y: auto;}
.filter_col {margin-top: 20px;}
.card_car h1, .card_car .card_item{margin-bottom: 16px;}
.mini_loader_box{width: 100%; display: flex; justify-content: center; margin: 30px 0;}
.box{padding: 90px 65px; display: flex; flex-direction: column; justify-content: center; min-height: 100vh; align-items: center;} 
.login_box{width: 500px; margin-top: 20px;}
.login_box h1{font-size: 40px; font-weight: 500; line-height: 48px; margin-bottom: 65px;}
.login_box label{color: #163048 !important; font-weight: 500;}
.login_box .login_item{margin-bottom: 25px;}
.login_box input{color: black; border-color: #AEAEB2 !important;}
.save_btn{width: 100%; display: flex; justify-content: center;}
.google_btn {font-size: 25px !important; justify-content: center;}
.links_box{display: flex; justify-content: space-between; width: 100%; margin-top: 20px;}
.links_box a{color: #AEAEB2; font-size: 16px; line-height: 17px;}

.login_item{position: relative;}
.see_btn{font-size: 25px; width: 30px; height: 30px;  display: flex; align-items: center; justify-content: center; position: absolute;
top: 36px; right: 10px; color: #AEAEB2; cursor: pointer;
}
.login_box button{margin: 0px;}
.login_box button:nth-child(2){margin-left: 10px;}
.user_box{display: flex; align-items: center;}
.user_avatar{width: 50px; height: 50px; background-size: cover; background-position: center; background-repeat: no-repeat; border-radius: 50%;}
.right_navbar_box{display: flex; align-items: center; padding-left: 20px;}
.user_text{padding-left: 10px;}
.user_text h3{text-align: left; line-height: 16px; color: black; font-weight: 500; margin-bottom: 5px; font-size: 16px;}
.user_text p{text-align: left; color: #AEAEB2; line-height: 14px; font-size: 14px;}
.right_list{position: absolute; max-height: 0; top: 105%; 
    right: 20px;
    border-radius: 20px; transition: 0.3s; overflow: hidden; background-color: white; padding: 0 20px; box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;}
.right_list li,.right_list li a{display: flex; align-items: center; flex-wrap: nowrap;}
.right_list li{margin: 15px 0; cursor: pointer;}
.right_list li span{font-size: 20px; color: black; margin-right: 10px; display: flex; align-items: center;}
.right_list li p{font-size: 16px; font-weight: 500; color: black; white-space: nowrap;}

.open_right_box .right_list{max-height: 400px; padding: 5px 20px;}


body:has(.client) .footer, body:has(.big_pro_box) .footer, body:has(.sel_selector_box) .footer {
    display: none;
}

.client_box {
    display: flex;
    width: 100vw;
    padding: 0vw;
    padding-top: 86px;
    padding-right: 0px;
    height: 100vh;
    background-color: #eaeef1;
}

.client_menu {
    width: 70px;
    transition: 0.3s;
    height: 100%;
    overflow: hidden;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 7px;
    
}
.open_side_bar{width: 270px;}

.client_menu_header {
    height: 40px;
    padding: 0 10px;
    display: flex;
    background-color: #E7CEB7;
    align-items: center;
    justify-content: center;
}
.menu_tab{display: flex; height: 100%; align-items: center; font-size: 35px; transition: 0.3s;}
.open_side_bar .client_menu_header{justify-content: flex-end;}

.client_user_text {
    display: flex;
    flex-direction: column;
}

.client_user_text h3 {
    font-weight: 500;
    font-size: 1.1vw;
    color: white;
}

.client_user_text p {
    font-size: 0.9vw;
    color: white;
}

.client_menu_list {
    padding: 0px 0vw 50px;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: #E7CEB7;
    height: calc(100vh - 100px);
}

.client_list_item {
    display: flex;
    margin: 10px 0;
    align-items: center;
    height: 40px !important;
    padding:0 10px;
    margin-top: 10px;
}

.client_list_item img{width: 35px;  transition: 0.3s;margin:0 20px 0 7.5px; }
.open_side_bar .client_list_item img{width: 30px; margin: 0 15px 0 0;}

.client_list_item p {
    color: black;
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    line-height: 16px;
}





.client_content {
    width: calc(100% - 70px);
    margin-left: 0vw;
    transition: 0.3s;
    min-height: 100%;
}
.client:has(.open_side_bar) .client_content{ width: calc(100% - 270px);} 
.active_list_item {
    background-color: #F1E0D0; height: 45px;
}



.profile {
    display: flex;
    width: calc(100%);
    min-height: 100%;
    padding: 10px;
    background-color: white;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 7px;
}

.profile_img {
    width: 330px;
    padding: 1vw;
}

.profile_box {
    width: calc(100% - 330px);
    display: flex;
    flex-direction: column;
}

.profile_avatar {
    width: 100%;
    height: 350px;
    /* background-image: url(./images/icons/random_avatar_man.jpg); */
    margin-right: 0.5vw;
    border: 5px solid #e19e5e;
    border-radius: var(--border_radius);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.client_filter_title {
    font-weight: 600;
    color: #e19e5e;
    font-size: 22px;
    padding: 10px;
    padding-top: 0;
    border-bottom: 3px solid #e19e5e;
}

.filter_two {
    margin-top: 20px;
}

.card_item span {
    display: flex;
    align-items: center;
}



.client_menu_list::-webkit-scrollbar{display: none;}
.client_dropdown_head {
    text-transform: uppercase;
    display: flex; justify-content: center;
    padding: 0;
    opacity: 0;
    transition: 0.3s;
    margin-top: 20px !important;
    width: 100%;
    height: auto !important;
}

.client_dropdown_head p {
   font-size: 18px;
   line-height: 25px; 
   font-weight: 700;
   color: black;
   
  
}

.open_side_bar .client_dropdown_head{opacity: 1;}

.alert_text {
    padding:0 10px;
   
    margin-top: 1vw;
   
    color: #bec2c6;
    font-size:16px;
    text-indent: 0px;
    font-weight: 500;
}

.alert_icon {
    font-size: 22px;
    position: relative;
    top: 4px;
    margin-right: 5px;
}

.announcment_client {
    transition: scroll-behavior 0.3s;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.announcment_filter {
    width: 100%;
   
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 7px;
}

.announcment_filter_head {
    display: flex;
    overflow: hidden;
    transition: 0.3s;
    height: 60px;
    align-items: center;
    background-color: #e19e5e;
    justify-content: space-between;
    padding-right: 20px;
}

.announcment_filter_head h1 {
    font-size: 20px;
    transition: 0.3s;
    padding: 10px 20px;
   
    width: 60%;
    cursor: pointer;
    font-weight: 600;
    text-transform: uppercase;
    color: white;
}



.client_search {
    position: relative;
    display: flex;
    width: 35%;
}
.client_search input{border-color: white !important; padding-right: 45px;}

.client_search .search_button{position: relative; right: 45px; font-size: 22px; display: flex; align-items: center; color: white; cursor: pointer;}

.client label, .client input{color: black !important;}
.client label{font-weight: 500;}
.client .select_input p{color: black;}
.client input::placeholder,.client #choose p{color: #666666;}
.client .card_text,.client .card_mini_text, .client .card_img{background-color: white !important;}
.client .card_price{flex-direction: column !important; align-items: flex-start;}
.client .card_price span{margin-top: 10px;}
.client_search input::placeholder, .client_search input{color: white !important;}
.client .filter_btns{justify-content: flex-end;}
.client .filter_btns .save_btn{width:auto !important;}
.client .mini_card_col{padding: 15px;}
.client .card_mini_img{height: 340px;}
.client .card_price p{font-weight: 500;}

.filter_box .save_btn{width:auto}
.yandex_map {
    height: auto;
    width: 40%;
    
    margin-top: 1.5vw;
}
.yandex_map_box{
    padding: 0.7vw;
    border-radius: var(--border_radius);margin-bottom: 1vw;
   
}

.yandex_box {
    width: 100%;
   display: flex; 
   align-items: center;
   flex-direction: column;
    height: 60vh;
    border-radius: 0 0  var(--border_radius) var(--border_radius);
    overflow: hidden;
    position: relative;
    z-index: 123;
    overflow: hidden;
    border:2px solid transparent;
}

.yandex_input{width: 100%; display: flex; margin-bottom: 10px;}
.yandex_input button{background-color: #8f999c; width: 50px; border-radius: 0px 10px 10px 0px; font-size: 30px; color: white; display: flex; align-items: center; justify-content: center;}
.yandex_input input{border-radius: 10px 0 0 10px; border-right: none;}
.open_filter{max-height: 800vh;}
.yandex_box label{display: none;}
.yandex_box:has(video){
    height: auto;
}
.announcment_filter_body {
    padding: 0vw;
    transition: 0.3s;
    
    background-color: white;
    max-height: 0;
    overflow: hidden;
}

.client_open_filter .announcment_filter_body {
    padding: 1vw 0.5vw;
    transition: 0.3s;
    max-height: 1000vh;
    overflow: visible;
}



.client_card_box {
    padding: 1vw 0;
}

.client_card_box .card_mini {
    padding: 0;
    margin-top: 0px;
    cursor: auto;
    margin-bottom: 1vw;
}

.card_btns {
    width: 100%;
}

.card_btns_edit {
    width: 100%;
    display: flex;
}

.card_btns_edit button, .edit_btn {
    width: 33.4%;
}

.card_btns button, .edit_btn {
    height: 50px;
    transition: 0.3s;
    font-size: 20px;
    color: white;
}
.watch_btn{background-color: #163048 !important;}
.card_btns button div, .edit_btn div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    background-color: transparent;
}


.edit_btn {
    background-color: #f2b705;
}

.delete_btn {
    background-color: #df4151;
}

.btn_client {
    width: 100%;
    font-size: 16px !important;
    font-weight: 500;
}

.client_card_text {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 30px;
    height: 30px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 23;
    border-radius: 50%;
    border: 3px solid white;
    box-shadow: rgba(0, 0, 0, 0.08) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.06) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

.denger_text {
    background-color: #ff0000;
}

.active_text {
    background-color: #00ff6e;
}

.active_btn {
    background-color: #03c66b;
}

.no_active_btn {
    background-color: #c60303;
}
.announcment_btns {
    padding: 0.5vw 1vw;
    width: 100%;
   
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 7px;
    background-color: white;
    margin-top: 1vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0vw;
    z-index: 340;
}

.active_btns_item {
    display: flex;
    align-items: center;
    margin: 5px 0;
}

.active_btns_item .client_card_text {
    position: static;
    width: 30px;
    height: 30px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 15px 0px inset, rgba(0, 0, 0, 0.06) 0px 0px 10px 0px inset, rgba(0, 0, 0, 0.1) 0px -39px 10px 0px inset, rgba(0, 0, 0, 0.06) 0px 0px 0px, rgba(0, 0, 0, 0.09) 0px 0px 0px, rgba(0, 0, 0, 0.09) 0px 0px 0px, rgba(0, 0, 0, 0.09) 0px 3px 3px, rgba(0, 0, 0, 0.09) 0px 12px 10px;
}

.active_btns_item p {
    margin-left: 5px;
    font-size: 16px;
    font-weight: 500;
    color: #333839;
}

.active_btns_btn {
    padding: 0 !important;
}

.filter_btns button span {
    font-size: 20px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.active_btns_btn {
    width: auto;
    display: flex;
    align-items: center;
}

.success_btn {
    background-color: #00c455;
    color: white;
}

.selected_text_num {
    margin-right: 0.5vw;
    font-weight: 500;
    font-size: 1vw;
    color: #333839;
    margin-bottom: 0;
}

.ant-tooltip {
    z-index: 4444;
}

.quill {
    height: 55vh;
}

.ql-tooltip {
    z-index: 922 !important;
}

.ql-tooltip input {
    width: 170px !important;
}

.ql-toolbar {
    border-radius: var(--border_radius) var(--border_radius) 0 0 !important;
}

.ql-container {
    border-radius: 0 0 var(--border_radius) var(--border_radius) !important;
}

.file_input {
    padding-top: 10px;
    display: flex; align-items: center;
}

.file_text {
    font-size: 12px;
    color: #8f999c;
    padding-left: 10px;
    font-weight: 500;
}

.image_box {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #e19e5e;
    border-radius: var(--border_radius);
    /* user-select: none !important; */
}
.video_box video{height: 100%; width: auto !important;}
.image_box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.img_col {
    padding: 0.5vw !important;
}

.img_delete_btn {
    font-size: 35px;
    color: #c60303;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    transition: 0.3s;
    background-color: white;
}



.ant-message {
    z-index: 9999 !important;
}

.ant-message-error,
.ant-message-success {
    display: flex;
    align-items: center !important;
}

.ant-message-error span:nth-child(1),
.ant-message-success span:nth-child(1) {
    font-size: 22px !important;
    color: white !important;
}

.ant-message-error span:nth-child(2),
.ant-message-success span:nth-child(2) {
    font-size: 16px !important;
    color: white !important;
}

.ant-message-notice-error .ant-message-notice-content {
    background-color: #e50303 !important;
    padding: 10px 20px !important;
}

.ant-message-notice-success .ant-message-notice-content {
    background-color: #00c455 !important;
    padding: 10px 20px !important;
}

.loader {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9998;
    background-color: #ffffffe0;
    backdrop-filter: blur(10px);
}

.loader span {
    width: 13vw !important;
    height: 13vw !important;
}

.profile_img {
    position: relative;
}

.images_btns {
    position: absolute;
    top: 30px;
    right: 30px;
    display: flex;
    flex-direction: column;
}
.main_img_user{position: relative; margin-top: 20px;}

.images_btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background-color: white;
    border: 2px solid white;
    transition: 0.3s;
    overflow: hidden;
    cursor: pointer;
}
.main_img_user .images_btns{right: 10px; top: 10px;}
.main_img_user .profile_avatar{height: 240px;}
.images_btns .edit_btn {
    border-color: #f2b705;
    color: #f2b705;
    position: relative;
}



.images_btn input {
    position: absolute;
    top: 0vw;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.big_navbar .avatar {
    width: 3.5vw;
    height: 3.5vw;
}

.announcment_filter_body .alert_text {
    margin-top: 0;
    margin-bottom: 0.5vw;
   
}



.ql-editor {
    font-size: 16px;
}
.display_filter{height: 100px; opacity: 0 !important; overflow:hidden; }
.navbar_search_box input::placeholder{color: #AEAEB2;}
.navbar_search_box input{color: #252628; opacity: 0; width: 0px !important; padding: 0px; border-width: 0; transition: 0.3s; font-weight: 400; height: 40px !important;
     margin: 0px; border-radius: 10px 0 0 10px; border-right: none;}
.navbar_search_box{display: flex; align-items: center;}
.navbar_search_box .login_icon{height: 40px; cursor: pointer; font-size: 25px; width: 25px; display: flex; transition: 0.3s; 
    border-radius: 0 10px 10px 0; align-items: center; justify-content: center; margin-left: 0px;}
.open_search input{ width: 300px !important; padding:0 10px; border-width: 1px;opacity: 1;}
.open_search .login_icon{width: 40px; background-color: #AEAEB2; color: white;}
.open_search:hover .login_icon{color: white; background-color: #e19e5e;}
.open_search:hover input{border-color: #e19e5e !important; }
.filter_nav_btn{margin-left: 20px;}
.code_input{position: relative;}
.email_time{position: absolute; top: 0px; right: 0px; height: 100%; padding:0.3vw 0.5vw; font-weight: 500; color: #8f999c; 
    font-size: 1vw;
    background-color: transparent; display: flex; align-items: flex-end;}
    .google_btn {font-size: 1.6vw !important;}
    .agreed{width: 20px !important; margin-left: 11px; height: 20px;}
    .agreed_box{display: flex; height:40px; align-items: center; justify-content: flex-start;}
    .slash_b{font-size: 20px;}
    .mini_loader_box{width: 100%; display: flex; justify-content: center;}
    .active_image{position: absolute; top: 10px; left: 10px; width: 30px !important; height: 30px;}
    .til_box{display: flex; width: 100%; height: 100%; align-items: center; justify-content: space-between;}
    .til_span{display: flex;width: 50%;  border-bottom: 2px solid #333839; color: #333839 !important; font-weight: 500 !important; 
        font-size: 1.1vw !important; display: flex; cursor:pointer; justify-content: center;}
    .til_li{padding-top: 0 !important;}

    .white_text{color: transparent !important;}
    .add_contact_icon{ padding: 0vw !important;  display: flex; align-items: center; 
        justify-content: center;
         margin-right: 10px;}
        .add_contact_box{display: flex;}
        .add_car_box{width: 100%;}
        .as_add_car_card{width: 100%; padding: 0.5vw;}
        .as_add_car_card .card_mini_text_head h1{align-items: flex-start;}
        .react-multi-carousel-track{margin-bottom: 1.5vw;}
        .as_add_car_card .card_mini_img{height: 16vw;}
        .as_add_title{margin: 40px 0 0 0; font-weight: 600; padding: 10px 0; border-bottom: 2px solid #e19e5e; text-align: center; width: 100%; 
            color: #e19e5e; font-size: 30px;}
.video_full_box{margin-top: 30px;}
.yandex_box:has(video){display: flex; justify-content: center; align-items: center;}
#closed_ul{display: none !important;}

.selectors_div_box{display: flex; align-items: center; justify-content: center;}
.sel_selector_box{padding:5vw 20vw; display: flex; min-height: 80vh; flex-wrap: wrap;}
.sel_selector_card{padding: 0.5vw 1vw; border-radius: var(--border_radius);
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    color: #333839; font-weight: 500; font-size: 1vw; cursor: pointer;
    margin: 1vw; transition: 0.3s;
    background-color: white;}
  
    .login_big_box_btn{display: flex; align-items: center;}
    .login_big_box_btn .til_span{margin-right: 1vw; padding: 0 0.5vw;}
    .danger_back{border:2px solid #c60303}
   .search_box_filter{position: fixed; top: 1.35vw; left: 35vw; display: flex; z-index: 3444; width: 30vw;}
   .filter_open_btn{display: none;}
     .create_title{padding: 20px 20px 0px; text-align: center; background-color: white; font-size: 22px; font-weight: 700; text-transform: uppercase;}
     .box_product{display: flex; flex-wrap: wrap; padding: 100px 65px; min-height: 80vh;}
     .product_text{width: 50%; padding-top: 20px;}
     .product_img_box{width: 50%;}
     .head_text_title h1{font-size: 25px; line-height: 32px; color: black; font-weight: 700; margin-bottom: 10px;}
     .product_text{padding-right: 40px;}
     .product_text_head{ margin-bottom: 40px;}
     .head_text_title p{color: #666666; font-size: 16px; line-height: 20px;}
     .product_item{font-size: 16px; font-weight: 500; margin-bottom: 20px;}
     .product_item b{white-space: nowrap;}
     .product_price{font-size: 30px; font-weight: 700; color: #e19e5e; line-height: 40px; padding: 20px 0; border-top:  2px solid #E7CEB7; border-bottom:  2px solid #E7CEB7;}
     .add_contact_box{display: flex; align-items: center; justify-content: space-between; border-radius: 15px; margin-top: 30px; border: 2px solid #E7CEB7;
    padding: 10px 10px;
    }
    .ant-image-mask{opacity: 0 !important;}
    .add_contact_box .user_box{margin-right: 20px;}
    .add_contact_links{display: flex; align-items: center; flex-wrap: wrap;}
    .add_contact_box .avatar{width: 70px; height: 70px; border-radius: 15px; background-size: cover;  background-position: center; background-repeat: no-repeat;}
    .add_contact_box .user_text h1{font-size: 18px; color: black !important; font-weight: 600;}
    .add_contact_box .user_text a{font-size: 16px; color: #666666 !important; line-height: 18px; font-weight: 500; display: flex; align-items: center;}
    .add_contact_links a{width: 40px; margin: 5px; height: 40px; transition: 0.3s; border-radius: 50%; background-color: #E7CEB7; font-size: 20px !important; color: black;}
    .thumb_video{position: relative; width: 100%; height: 100%;}
    .thumb_video span{width: 100%; height: 100%; position: absolute; top: 0px; left: 0px; z-index: 1; display: flex; align-items: center; justify-content: center;
    color: white; font-size: 50px;
    }
    .box_product .yandex_map{ width: 100%; margin-top: 30px;}
    .box_product .yandex_map_box{padding: 0;}
    .box_product .yandex_box{border-radius: var(--border_radius) !important; overflow: hidden !important; border: 2px solid #E7CEB7;}
    .product_comment{padding-right: 20px; width: 100%;}
     .react-multi-carousel-track{padding-bottom: 40px !important;}
     .react-multi-carousel-dot button{width: 20px !important; height: 7px !important; border-radius: 5px !important; border-width: 1px !important; border-color: #E7CEB7 !important;}
     .react-multi-carousel-dot--active button{border-color: #e19e5e !important; background-color: #e19e5e !important;}
     .react-multiple-carousel__arrow--left{left: 100px !important; background-color: #e19d5eed !important; bottom: 0px !important;}
     .react-multiple-carousel__arrow--right{right: 100px !important; background-color: #e19d5eed !important; bottom: 0px !important;}
     .react-multi-carousel-dot-list{height: 40px; }
     .product_comment{margin-top: 30px;}
     .card_credit .card_mini_price {flex-direction: column; align-items: flex-start;}
     .phone_categories{display: none;}
     .phone_client_title{display: none;}
     .box_product{min-height: 100vh;}
     .announcment_client:has(.payments){ background-color: white;}
     .payments{padding: 50px 50px;}
     .user_text p{color: #e19e5e; font-weight: 500;}
     .payments_head{display: flex; align-items: center; width: 100%; justify-content: space-between;}
     .payments_head div{display: flex; align-items: center;}
     .payments_head p{margin-right: 10px; font-size: 16px; font-weight: 500; color: #252628;}
     .payments_head button{width: 150px;display: flex; align-items: center;  justify-content: center; border-radius: 20px; overflow: hidden;}
     .payments_head button img{width: 100%;transition: 0.3s;}
     .payments_head button:hover img{transform: scale(1.1, 1.1);}
     .input_summa{color: #252628 !important;}
     .input_summa::placeholder{color: #8f999c !important;}
     .payments_head b{font-size: 18px;}
     .news_box{width: 100%; padding: 90px 65px;}
     .news_col{padding: 20px;}
     .news_img{width: 100%; margin-bottom: 20px; height: 35vh; display: flex; align-items: center; justify-content: center;  overflow: hidden;
    background-size: cover; background-position: center; background-repeat: no-repeat; background-color: #E7CEB7; border-radius: 20px;
    }
    .news_card{cursor: pointer;}
    .news_card p{font-weight: 500; color: #333839; font-size: 16px; transition: 0.3s;}
    .news_card:hover p{color:#e19e5e}
    .news_one_box{padding: 120px 20vw 50px; min-height: 90vh;}
    .news_one_box h1{font-weight: 700; text-align: center; font-size: 22px;}
    
    .opne_new_images img{width: 100%;}
    .opne_new_img{display: flex; align-items: center; border-radius: 20px ; margin: 30px 0; justify-content: center; height: 70vh; overflow: hidden;}
    .main_banks_box{padding: 90px 65px;}
    .main_banks{ display: flex;  margin-top: 30px; flex-wrap: wrap; justify-content: space-between;}
    .bank_img{display: flex; align-items: center; justify-content: center;}
    .bank_card{transition: 0.3s; min-width:15vw; 
        box-shadow: rgba(14, 63, 126, 0.04) 0px 0px 0px 1px, rgba(42, 51, 69, 0.04) 0px 1px 1px -0.5px, rgba(42, 51, 70, 0.04) 0px 3px 3px -1.5px, rgba(42, 51, 70, 0.04) 0px 6px 6px -3px, rgba(14, 63, 126, 0.04) 0px 12px 12px -6px, rgba(14, 63, 126, 0.04) 0px 24px 24px -12px; margin: 10px; display: flex; align-items: center;
         background-color: white; padding: 15px; border-radius: 10px; }
    .autosalons .bank_card{background-color: #efefef !important;}
    .bank_img img{height: 60px;  margin-right: 10px; border-radius: 10px;}
    .bank_text p{font-weight: 500; font-size: 18px; color: #252628; }
    .bank_text a{font-size: 16px; color: #8f999c; display: flex; align-items: center;}
    
    .bank_icon{margin-right: 5px;}
    .bank_icon{display: flex; align-items: center; font-size: 23px; color: #252628;}
    .bank_card *{user-select: none;}
    .open_bank_box{min-height: 80vh; padding-top: 120px;}
    .one_bank_text{width: 60%; padding: 20px; position: relative; z-index: 3;  background-color: #ffffffc5; backdrop-filter: blur(5px); }
    .one_bank_head{display: flex; align-items: center; margin-bottom: 30px; }
    .one_bank_text p{font-size: 16px !important; color: #252628 !important;}
    .one_bank_head img{width: 70px;}
    .one_bank_head h1{font-weight: 700; font-size: 25px; margin-left: 10px; color: #252628 !important;}
    .one_bank_info{ margin-bottom: 50px; position: relative; border-radius: 20px; overflow: hidden;}
    .title_bank{font-size: 30px; margin-top: 90px; color: #e19e5e; font-weight: 700; padding-bottom:0px; text-align: center; text-transform: uppercase; border-bottom: 6px double #e19e5e;  }
    .credit_item{width: 100%; border-radius: 30px;  margin-top: 30px;
    background-color: #f3f3f3; padding:0px; overflow: hidden; position: relative;
    }
.credit_item button{display: flex;  transition: 0.3s; cursor: pointer; overflow: hidden; white-space: nowrap; position: relative; align-items: center;
     justify-content: center; font-size: 16px; margin: 20px 30px; font-weight: 700; color: #e48429;}
.desc_credit_item{overflow: hidden; position: relative; z-index: 3; padding: 0px 30px; margin-bottom: 20px; max-height:0; transition: 1s; background-color: #f3f3f3;}
.credit_item_img{width: 450px; z-index: 0; position: absolute; top: 0px; right: 0px; height:  380px; background-size: cover; 
    background-position: center; background-repeat: no-repeat;}
.credit_item .product_text{width: 100%;padding: 0px 30px;}
.credit_item .product_text_head{margin: 20px 0;}
.credit_item .product_text_head h1{font-size: 24px; width: max-content;  text-align: left; color: black;}
.credit_item .product_item, .credit_item .desc_credit_item *{color: #181817 !important;}
.credit_item button input{position: absolute; width: 100%; height: 100%; cursor: pointer;opacity: 0; }
.credit_item button:hover{color: #af5807;}
.credit_item:has(input:checked) .desc_credit_item{max-height:100vh; transition: 1s;}
.one_bank_icon{transform: rotate(0deg); transition: 0.3s; margin-left: 5px;}
.credit_item:has(input:checked) .one_bank_icon{transform: rotate(90deg);}
.credit_item .product_comment{margin-top: 10px;}
.bank_btns{display: flex; align-items: center; margin-top: 50px;}
.bank_btns button{margin-right: 20px; border-radius: 10px; transition: 0.3s; border: 2px solid #e19e5e; color: #e19e5e; padding: 10px 25px; font-size: 16px; font-weight: 500;}
.bank_active_btn{background-color: #e19e5e; color: white !important;}
.one_bank_img{background-position: center left; background-size: cover; width: 100%; height: 100%; position: absolute; top: 0px; right: 0px;}
.bank_contact{width: 100%; display: flex; flex-wrap: wrap; align-items: center; justify-content: flex-start; margin-bottom: 50px; }
.bank_contact a{min-width: 240px; margin-top: 20px; height: 50px; display: flex; align-items: center; justify-content: flex-start;  border-radius: 10px;
 transition: 0.3s; font-size: 23px; color: white; margin-right: 20px; padding: 0px 10px;
 } 
.telegram_btn{background-color: #28A8E9; border:2px solid #28A8E9;}
.website_btn{background-color: #d96e0a; border:2px solid #d96e0a;}
.instagram_btn{background-color: #ED2909; border:2px solid #ED2909;}
.facebook_btn{background-color: #3B579D; border:2px solid #3B579D;}
.calculator_btn{background-color: #1c963d; border:2px solid #1c963d;}
.bank_phone{background-color: #010716; border:2px solid #010716;}
.bank_contact a span{margin-left: 10px; font-size: 18px !important;  position: relative; top: 1px;}






.bank_map{border-radius: 20px; overflow: hidden; border: 2px solid #e19e5e;}
.car_one_box{display: flex; width: 50%; flex-wrap: wrap;}

.car_one_box .product_item{width: 50%; }
.one_car .credit_item_img{height: 475px; width: 40vw; }
.mobile_menu{display: none;}
.navbar_box .menu_tab{display: none;}
.exit_menu_side{margin-top: 30px;}
.mobile_menu{display: none;}
.navbar_box .menu_tab{display: none;}
.input_file_btn{position: relative; width: 100%; height: 50px; cursor: pointer; background-color: #e19e5e; display: flex; align-items: center; justify-content: center; border-radius: 10px;}
.input_file_btn input{position: absolute;cursor: pointer; top: 0px; left:0px; opacity: 0; z-index: 1;  width: 100%; height: 100%;} 
.input_file_btn{font-weight: 500; color: white; font-size: 22px; text-transform: uppercase;}
.input_file_btn p{ margin-right:5px; font-size: 16px; line-height: 16px; display: flex; align-items: center;}
.product_price_auto{margin-bottom: 20px !important; display: flex; font-size: 22px; color: #e48429;}
.one_car .product_text_head{margin-bottom: 0px;}
.ant-image-preview-root .ant-image-preview-mask{background-color: #000000e4 !important; backdrop-filter: blur(10px) !important;}
.car_one_carousel{position: absolute; top: 0px; right: 0px; width: 40vw; height: 455px; background-color: white;}
.car_one_box .product_item{margin-bottom: 10px;}
.car_one_image{width: 40vw; height: 452px; display: flex; align-items: center; justify-content: center;}
.retro_col{width: 33.33%; padding: 20px;}
.retro_card{width: 100%;  transition: 0.3s;
    display: flex; flex-direction: column; border-radius: 20px; overflow: hidden; background-color: #ECECEC !important;}
.main_retros{display: flex; flex-wrap: wrap;}
.retro_img{width: 100%; background-color: white; display: flex; align-items: center; justify-content: center; overflow: hidden; height: 35vh;}
.retro_img img{width: 100%; transition: 0.3s;}
.retro_text{padding: 20px 15px; }
.retro_text_p{font-size: 16px; transition: 0.3s;  font-weight: 500; color: #181817;}

.retro_car_images{display: flex; margin: 30px 0; align-items: center; flex-wrap: wrap; justify-content: space-between;}
.retro_car_image_item{width: 32%;  height: 35vh; display: flex; align-items: center; margin-bottom: 40px; justify-content: center; overflow: hidden; border-radius: 15px;
overflow: hidden;
}
.retro_car_image_item img{width: 100%; transition: 0.3s;}

.retro_car_one h1{font-size: 22px; font-weight: 700; color: #181817;  text-transform: uppercase;}
.retro_car_one h3{font-size: 18px; font-weight: 500; color: #8f999c; margin-bottom: 20px;  text-transform: uppercase;}
.retro_car_one p{font-size: 16px; font-weight: 400; color: #181817; margin-bottom: 20px; text-align: justify;}
.retro_car_image_item video{width: 100%;}
.retro_car_head{display: flex; justify-content: space-between; align-items: center;}
.retro_car_head_text{width: 90%;}
.retro_like{font-size: 30px; width: 10%; transition: 0.3s; display: flex; align-items: center; justify-content: center;}

.retro_text{display: flex; align-items: center; justify-content: space-between;}
.retro_text button{font-size: 24px; display: flex; align-items: center;}
.disable_btn{background-color:#6D6D6D !important}
.retro_cars_big{min-height: 40vh; padding-top: 100px !important;}
.open_filter_btn{background-color: #e19e5e; color: white;}
.search_big_box{width: 100%;padding: 20px 20%;}
.search_big_input{ display: flex; align-items: center; justify-content: center; width: 100%; border: 2px solid #e19e5e; border-radius: 15px; overflow: hidden;}
.search_big_input input{border: none; color: black;}
.search_big_input input::placeholder{color: #DDD0C8;}
.search_big_box button{background-color: #e19e5e;  padding: 0 15px; display: flex; align-items: center; border-radius: 0; font-size: 25px; color: white; height: 50px !important; }
.search_big_box button:hover{background-color:#d96e0a;}
.elon_img_box{width: 100%; height: 100%; background-position: center; background-size: cover; background-repeat: no-repeat;}
.filter_nav_btn:has(img){border: none;}
.filter_nav_btn img{width: 85%;}
.til_btn img{width: 30px; margin-left: 20px;}
.apk_btns{display: flex; align-items: center; padding: 20px 0;  border-top: 2px solid #252628; flex-wrap: wrap;}
.apk_btns p{font-size: 16px; font-weight: 500;}
.apk_btns img{height: 55px; border-radius: 10px;}
.apk_btns a{margin-left: 40px; transition: 0.3s;}
.apk_btns a:hover{transform: scale(1.1, 1.1);}

@media (min-width:1024px){
        .telegram_btn:hover{background-color: white; color:#28A8E9;}
        .website_btn:hover{background-color: white; color:#d96e0a;}
        .instagram_btn:hover{background-color: white; color:#ED2909;}
        .facebook_btn:hover{background-color: white; color:#3B579D;}
        .calculator_btn:hover{background-color: white; color:#1c963d;}
        .bank_phone:hover{background-color: white; color:#010716;}
        .retro_card:hover img{transform: scale(1.1, 1.1);}
        .retro_car_image_item:hover img{transform: scale(1.1, 1.1);}
        .retro_like:hover{transform: scale(1.1, 1.1);}
    .bank_card:hover{background-color: #f9dcc2; transform: scale(1.05, 1.05);}
    .autosalons .bank_card:hover{background-color: #e5e5e5 !important; transform: scale(1.05, 1.05);}
    .navbar_link:hover{color: #e19e5e;}
    .carousel_btn:hover{background-color: #e19e5e;}
    .big_cart:hover .big_cart_text{color: #e19e5e;}
    .big_cart:hover .big_cart_image{border-radius: 0;}    
    .big_cart:hover .big_cart_image img{transform: scale(1.1, 1.1);} 
    .add_contact_links a:hover{background-color: #e19e5e;}
    .more_item:hover p, .more_item:hover span{color: #e19e5e;}
.filter_nav_btn:hover{background-color: #e19e5e; border-color: #e19e5e; color: white;}
.login_icon:hover{color: #e19e5e;}
.best_card:hover .best_arrow{color: white; background-color:#e19e5e}
.best_card:hover .best_img_img{transform: scale(1.2, 1.2);}
.categories_card:hover h1{color: #e19e5e;}
.carousel_text_head_item:hover{color: #252628;}
.footer_links a:hover{color: #e19e5e;}
.footer_bottom_content a:hover{color: #e19e5e;}
.card:hover img{transform: scale(1.1, 1.1);}
.card:hover{color: black;}
.links_box a:hover{color: #e19e5e;}
.right_list li:hover p, .right_list li:hover span{color: #e19e5e;}
.client_list_item:hover {
    background-color: #F1E0D0;
}
.client_dropdown_head:hover {
    background-color: #E7CEB7;
    border-radius: 0;
}
.announcment_filter_head h1:hover {
    background-color: #00000030;
}
.client_search .search_button:hover{color: black;}
.search_button:hover {
    border-color: white;
}
.yandex_input button:hover{background-color: #e19e5e;}
.card_btns button:hover div, .edit_btn:hover div {
    background-color: #00000030;
    
}

.edit_btn:hover{color: white;}
.img_delete_btn:hover {
    transform: scale(1.1, 1.1);
    color: #e50303;
}
.images_btns .edit_btn:hover {
    background-color: #f2b705;
    color: white;
}
.til_li:hover{background-color: white !important;}
.active_lang, .til_span:hover{ color: #e19e5e !important; border-bottom: 2px solid #e19e5e !important;}
.sel_selector_card:hover{background-color: #e19e5e; color: white;}
}

@media (min-width:1650px){
    .big_col{width: 25% !important;} 
    .big_cart_image{ height: 300px;}
    .big_box_products:has(.card_mini){padding: 0 0px;}
    .mini_card_col {padding: 25px 65px;}
    .card_mini_img{height: 350px;}
}

@media (max-width:1024px){
    .til_btn img{width: 27px; margin-left: 10px;}
    .sorter_shape{opacity: 0;}
    .money_type{opacity: 1;}
    .retro_car_one{padding-top: 100px !important;}
    .retro_col{width: 50%; padding: 10px;}
    .retro_img {height: 18vh;}
    .retro_car_image_item{width: 48%; height: 18vh; border-radius: 20px;}
    .car_one_carousel{position: static; width: 100%;}
    .car_one_image{width: 100%; height: 452px;}
    .main_banks_box{padding: 5vw;}
    .main_banks{justify-content: flex-start;}
    .bank_col{width: 50%;}
    .news_box{padding: 0 5vw;}
    .news_img{height: 20vh;}
    .news_col{padding: 15px;}
    .navbar_box .user_text {display: none;}
    .big_navbar{padding: 0 5vw;}
    .main{width: 100vw; position: relative; overflow: hidden;}
    .main_item {width: 100%; animation-duration: 10s; animation-iteration-count: infinite;}
    .main_text{padding: 5vw;}
    .main_text p{text-align: center;}
    .main_home{position: absolute; top: 0px; left: 0px; animation-name: main_home;}
    .main_car{position: absolute;  top: 0px; left: 100%; animation-name: main_car;}
    @keyframes main_home {
        0%{left: 0px;}
        25%{left: 0px;}
        50%{left:-100%; z-index: -1;}
        51%{left:100%; }
        75%{left:100%; }
        99.99%{left:0px; z-index: 1;}
    }
    @keyframes main_car {
        0%{left: 100%;}
        25%{left: 100%;}
        50%{left:0%; z-index: 1;}
        75%{left:0%; }
        99.99%{left:-100%; z-index: -1;}
        100%{left:100%; z-index: -1;}
    }
    .carous, .dash_categories, .big_box_products{padding:0 5vw;}
    .bests{padding: 5vw; flex-wrap: wrap;}
    .best_col{width: 100%; padding: 0px !important;}
    .carousel_item{flex-direction: column;}
    .carousel_img, .carousel_text{margin-top: 40px; width: 100%;}
    .carousel_text{ padding: 0px !important; order: 2;}
    .carousel_img{order: 1;}
    .carousel_img_item{border-radius: 30px !important;}
    .carousel_text_head_item { white-space: nowrap; overflow: hidden;}
    .categories_card h1{font-size: 20px; margin-bottom: 10px;}
    .categories_card:nth-child(odd){margin-top: 30px;}
    .categories_card:nth-child(even){margin-bottom: 30px;}
    .footer_top, .footer_menu{flex-wrap: wrap;}
    .footer_img{order: 1;}
    .footer_links{order: 2;}
    .footer_menu{order: 3;}
    .footer_menu .more_item{width: auto; margin: 10px 0;}
    .footer_menu{width: 100%;}
    .footer_top{padding: 5vw; padding-bottom: 20px;}
    .footer_bottom_content{flex-wrap: wrap; justify-content: flex-start;}
    .footer_bottom_content a{margin: 15px 15px;}
    .big_box_cat{padding: 130px 0px 90px;}
    .big_col{width: 50%;}
    .big_cart_text{font-size: 20px; line-height: 28px; height: 60px;}
    .products_filter{background-size: cover;}
    .filter_box{padding: 120px 5vw 30px;}
    .products_sorter{margin: 20px 0; margin-top: 100px;}
    .big_box_products:has(.card_mini){padding: 2.5vw;}
    .mini_card_col{padding: 10px 2.5vw;}
    .card_mini_img{height: 250px;}
    .card_mini_price{flex-wrap: wrap;}
    .product_text{order: 2; width: 100%;}
    .product_img_box{order: 1; width: 100%;}
    .add_car_box{order: 3;}
    .box_product{padding: 100px 5vw;}
    .yandex_box{height: 40vh;}
    .as_add_car_card .card_mini_img{height: 250px;}
    .card_text, .card_mini_text{padding: 20px 15px;}
    .card_credit .card_price p{font-size: 18px;}
    .right_list{right: 5vw;}
    .google_btn{font-size: 30px !important;}
    .menu_tab{opacity: 0;}
    .profile{flex-wrap: wrap;}
    .profile_box{width: 100%;}
    .alert_text{width: 85vw;}
    .open_side_bar{ width: 80px;}
    .open_side_bar .client_list_item img{width: 45px;  transition: 0.3s;margin:0 20px 0 7.5px; }
    .open_side_bar .client_dropdown_head{opacity: 0;}
    
    .client:has(.open_side_bar) .client_content{ width: calc(100% - 80px);} 
    .client_search{width: 50%;}
    .active_btns_box{width: 100%; display: flex; margin-bottom: 20px;}
    .announcment_btns{flex-direction: column; padding: 20px;}
    .active_btns_item:nth-child(1){margin-right: 30px;}
    .announcment_btns .active_btns_btn{justify-content: flex-start !important; width: 100%;}
    .client .card_mini_img{height: 250px;}
    .client .mini_card_col:nth-child(odd){padding-right: 7.5px;}
    .client .mini_card_col:nth-child(even){padding-left: 7.5px;}
    .client .card_price b{display: flex; width: 100%; height: 5px; opacity: 0;}
    .quill{height: 50vh; margin-bottom: 40px;}
    .image_box{height: 30vh;}
    .client_list_item{height: 60px !important;}
    .client_dropdown_head{height: 10px !important;}
    .announcment_filter_head{padding-right: 0px !important;}
    .footer_bottom{padding: 0 5vw;}
    .navbar_search_box {position: relative;}
    .navbar_search_box .login_icon{width: 40px ; }
    .open_search input{position: absolute !important; top: 0px !important; right: 40px !important; z-index: 4555; background-color: white;}
    .filter_nav_btn{height: 40px;}
    .news_one_box{padding: 110px 5vw 50px;}
    .opne_new_img{height: 30vh;}
    .one_bank_info{margin-top: 80px;}
    .one_bank_text{width: 100%; padding: 0px;}
    .one_bank_img{position: relative; top: 0px; left:0px; width: 100%; height: 40vh; margin-top: 30px;}
    .one_bank_info{flex-direction: column;}
    .bank_contact{flex-wrap: wrap;}
    .bank_contact a{width: calc(50% - 20px); min-width: 0; margin:0 10px; margin-bottom: 20px;}
    .one_car .credit_item_img{position: static; width: 100%; height: 30vh;}
    .car_one_box{width: 100%;}
}
@media (max-width:767px){
    .search_big_box{padding: 0 5vw 20px 5vw;}
    .retro_car_head{ flex-direction: column; align-items: flex-start; margin-bottom: 15px;}
    .retro_car_head_text{width: 100%;}
    .retro_like{font-size: 25px; width: 100%; margin-top: 10px; height: 100%; display: flex; align-items: flex-end; justify-content: flex-start;} 
    .retro_car_image_item{width: 100%; height: 25vh; border-radius: 20px;}
    .retro_car_one h3{display: none;}
    .retro_col{width: 100%; padding: 10px 0;}
    .retro_car_one h1, .retro_car_one h3{font-size: 18px; margin-bottom: 0px;}
    .retro_car_one p{font-size: 16px;}
    .retro_img {height: 25vh;}
    .retro_car_one{padding-top: 100px;}
    .car_one_carousel{height: 25vh;}
    .car_one_image{height: 25vh;}
    .navbar_left ul{display: none;}
    .navbar_search_box input, .navbar_search_box .login_icon{transition: 0s !important;}
    .open_search{position: absolute; right: 0px; height: 100%; background-color: white; width: 100%; justify-content: center;}
    .open_search input{position: static !important; width: 80% !important;}
    .big_navbar{height: 75px;}
    .brand img{height: 60px;}
    .main{height: 70vh;}
    .bank_col{width: 100%;}
    .filter_nav_btn{border: none; padding: 0; font-size: 24px; width: 40px; height: 40px; display: flex; align-items: center; justify-content: center;}
    .filter_nav_btn span{display: none;}
    .main_text p{font-size: 26px; line-height: 40px;}
    .phone_categories{display: flex; flex-wrap: wrap; padding: 20px 2.5vw;}
    .phone_category{padding: 20px 2.5vw; width: 50%;}
    .phone_category_cart{width: 100%; padding:15px 10px ; border: 1px solid #E7CEB7; display: flex; flex-direction: column; align-items: center; border-radius: 20px;}
    .phone_category_img{width: 80px; height: 80px; margin-bottom: 15px; display: flex; align-items: center; justify-content: center; border-radius: 50%; background-color: #E7CEB790;}
    .phone_category_img img{height: 45px;}
    .phone_category_text{font-size: 16px; font-weight: 500; line-height: 20px; height:40px; text-align: center; width: 100%; color: #252628;}
    .title{font-size: 30px; line-height: 41px; width: 100%;}
    .best_card{flex-direction: column; margin-bottom: 20px;}
    .best_img{width: 100%; border-radius: 0px;height: 260px; }
    .best_img_img {border-radius: 0;}
    .best_text{width: 100%; padding: 10px 0;}
    .best_arrow{display: none;}
    .best_row{margin-top: 20px !important;}
    .apk_btns{justify-content: center;}
    .apk_btns p{width: 100%; font-size: 18px; font-weight: 700; text-transform: uppercase; text-align: center; margin-bottom: 20px;}
    .apk_btns img{height: auto; width: 150px; }
    .apk_btns a{margin-left: 20px; margin-right: 20px; margin-top: 20px; }
    .carousel_img{height: 50vh;}
    .carousel_text_head_item {width: max-content !important; overflow: visible;}
    .carousel_text_head{overflow-x: auto; width: 100%;}
    .carousel_text_head::-webkit-scrollbar{display: none;}
    .carousel_text_data h1{font-size: 30px; line-height: 40px;}
    .carousel_text_data>p{margin-bottom: 30px;}
    .carousel_text_box{height: 270px;}
    .car_carous .carousel_text_box{height: 350px;}
    .categories_card{width: 100%; padding: 0px !important;}
    .categories_card:nth-child(odd){margin-top: 0px;}
    .categories_card{margin-top: 20px;}
    .footer_links a{font-size: 30px;}
    .footer_img img{width: 70px;}
    .footer_bottom_content a{width: 100%; font-size: 16px;}
    .box{padding: 90px 5vw;}
    .login_box{width: 100%;}
    .login_box h1{font-size: 30px; line-height: 40px; margin-bottom: 30px;}
    .big_col{width: 100%; padding: 15px 5vw;}
    .big_box_cat{padding-top: 80px;}
    .big_cart_image{height: 270px;}
    .big_cart_text{height: 30px; overflow: hidden; margin-bottom: 20px;}
    .filter_box{padding: 90px 3vw 30px;}
    .filter_col{margin-top: 10px;} 
    .filter_box .ant-col-sm-24, .profile_box .ant-col-sm-24, .announcment_filter_body .ant-col-sm-24{ width: 100% !important;}
    .filter_box .ant-col-sm-12, .profile_box .ant-col-sm-12,  .announcment_filter_body .ant-col-sm-12{ width: 50% !important;}
    ::-webkit-scrollbar{display: none;}
    .big_box_products:has(.products_sorter){padding: 0 5vw;}
    .sorter_select{width: 100% !important; margin-bottom: 30px;}
    .sorter_box{ flex-wrap: wrap; justify-content: space-between;}
    .products_sorter{margin-bottom: 0px;}
    .sorter_shape{margin-left: 0;}
    .card_mini_img{height: 260px;}
    .box_product{padding: 100px 3vw 50px;}
    .add_contact_box{flex-direction: column; align-items: flex-start; margin-top: 20px;}
    .add_contact_links{margin-top: 20px; width: 100%; justify-content: space-between;}
    .add_contact_links a{width: 45px; height: 45px; font-size: 22px !important;}
    .yandex_box{height: 30vh;}
    .yandex_map{margin-top: 20px !important;}
    .head_text_title h1{font-size: 22px;}
    .filter_nav_btn{margin-left: 10px;}
    
    .product_text{padding: 0px; margin-top: 20px;}
    .product_img_box_box{border-radius: 20px !important; overflow: hidden;}
    .as_add_title{font-size: 23px; padding: 5px 0; margin-bottom: 20px;}
    .client_box{padding-top: 70px;}
    .client_menu {position: fixed; top: 70px; z-index: 890; left: -100vw; background-color: #E7CEB7; width: 100vw; width: 100vw;}
    .client_content{width: 100vw !important;}
   
    .menu_tab{width: 35px; height: 35px; display: flex !important; align-items: center; justify-content: center; 
        border-radius: 0 20%  20% 0; opacity: 1; font-size: 30px;
        background-color: white; margin-left: 10px;}
    
        .navbar_box .right_navbar_box {display: none;}
        
        .client_menu_header{display: none;}
    .open_side_bar{left: 0px;}
    .client_list_item{height: 45px !important; padding-right: 40px !important;}
    .client_list_item img{width: 32px !important; margin-right: 10px !important; margin-left: 0px !important;}
    .client_dropdown_head{display: none;}
    .client_list_item{padding: 0 15px; }
    .alert_text{width: 100%;}
    .profile{justify-content: center;}
    .profile_img {width: 100%;}
    .profile_avatar{height: 50vh;}
    .phone_filter{display: none;}
    .announcment_filter_head h1{display: none;}
    .announcment_filter_head{background-color: white; height: auto;}
    .client_search{width: 100%; padding: 5vw 5vw;}
    .client_search input{border-color: #8f999c !important; color: #252628 !important; margin-right: 0px; width: 100%; border-radius: 10px 0px 0px 10px;}
    .client_search .search_button{color: white; right: 0px; background-color: #8f999c; display: flex; justify-content: center;  border-radius: 0px 10px 10px 0px; width: 15%; }
    .client_search input::placeholder{color: #8f999c !important;}
    .phone_client_title{padding:5vw; background-color: white;display: flex; justify-content: center; text-transform: uppercase; font-size: 22px; font-weight: 700;
     color: #252628;
    }
    .announcment_btns, .announcment_filter{margin: 0px; box-shadow: none !important;}
    .announcment_filter{border-top: 1px solid #e19e5e;border-bottom: 1px solid #e19e5e;}
    .active_btns_box{flex-direction: column;}
    .active_btns_btn{flex-direction: column; align-items: flex-start;}
    .active_btns_btn a{margin-left: 0px; padding-left: 0px; margin-top: 20px;}
    .active_btns_btn button{margin: 0px;}
    .announcment_btns{position: static;}
    .client_menu_header{height: 50px; padding-top: 10px;}
    .quill {height: 40vh; margin-bottom: 60px;}
    .image_box{height: 45vh;}
    .yandex_box:has(.yandex_input){height: 45vh;}
    .filter_col_right{padding-right: 10px;}
    .news_box{padding: 0 5vw;}
    .news_img{height: 25vh;}
    .news_col{padding: 15px 0;}
    .news_one_box{padding: 100px 5vw 50px;}
    .opne_new_img{height: 25vh;}
    .news_one_box h1{font-size: 20px;}
    .payments_head, .payments_head>div{flex-direction: column; align-items: center;}
    .payments_head>div{margin-bottom: 30px;}
    .one_bank_text{width: 100%;}
    .one_bank_head img{width: 50px;}
    .one_bank_head h1{font-size: 20px; width: 100% !important;}
    .credit_item .product_text_head h1{font-size: 18px; width: 100% !important;}
    .bank_btns{display: none;}
    .product_text{position: relative; z-index: 5; background-color: transparent;
        margin-top: 0px; padding: 15px 15px !important;}
        .product_text_head{margin-top: 0px !important;}
        .credit_item button{z-index: 5; background-color: transparent;  width: 100%; margin: 0;
            display: flex; justify-content: flex-start; color: #181817;
            padding: 15px;}
            .title_bank{font-size:20px ;}
            .credit_item:has(input:checked) .desc_credit_item{max-height:200vh; transition: 1s;}
            .one_bank_img{height: 30vh;}
            .bank_contact{flex-wrap: wrap;}
            .one_bank_info{border-radius: 0px !important;}
            .bank_contact a{width: 100%; margin:0; margin-bottom: 20px;}
            .credit_item_img{position: static; order: 1; height: 30vh; width: 100%; background-position: center; background-size: cover;}
            .car_one_box{flex-direction: column; width: 100%;}
           .car_one_box .product_item{width: 100%;}
            .big_box_products .ant-col{width: 100% !important;}
            .big_box_products .card{width: 100%;}
            .mobile_menu{display: block;}
            .pc_menu{display: none;}
            .client_menu_list{padding-top: 20px;}
            .exit_menu_side{margin-bottom: 30px;}
            .ant-col-sm-24{width: 100%;}
}